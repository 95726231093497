import { DATA_STATUS } from "constants.js";
import {
  calculateBirdAge,
  dateAdd,
  dateDiffInMilliseconds,
  dateSubtract,
  localDate,
  localDateFromSQL,
  localDateToSQL,
  localDateToSQLDate,
  sqlDateObjectFromServerTZ,
  startOfFromDate,
} from "./dateUtilities";
import { getReadonlyFormValueByFieldType } from "./formsUtilities";
import {
  calcAverage,
  calcCoefficiencyVariance,
  calcStandardDeviation,
  roundToNearest,
} from "./mathUtilities";
import { isNullEmptyOrWhitespace, isNumeric } from "./stringUtilities";
import { isEqual } from "./comparisionUtilities";
import { FORM_DATA_QUEUE_STATUS } from "db/FormDataQueue";

const DEFAULT_DATASOURCE = "this";
const DEFAULT_PROPERTY = "Value";

export interface IFormValueDataSources {
  [key: string]: any;
}

export interface IForm {
  FormFields: IFormField[];
  FormName: string;
  FormSubTitle: string;
  FormTitle: string;
  FormType: string;
  MaxScore: number;
  Permissions: string[];
  Schedule: number;
  ModuleName: string;
  HasFormValues: boolean;
  Terms?: string;
  RepeaterFields?: IRepeaterField[];
}

export interface IListOption {
  Id: string | number;
  Text: string;
  Value: string | number;
  Days?: number;
  IsNcn?: boolean;
  Parent?: any;
  Position?: number;
  Score?: number | null;
  SeverityColour?: number | null;
  Color?: string;
}

export interface IFormFieldDisplayType {
  edit: {
    position?: number | null;
    dependencies?: string[];
    color?: string;
    searchable?: boolean;
  };
  list: { position: number | null };
}

export interface IFormField {
  Ref: string;
  QuestionGroup: string | null;
  Name: string;
  Calculation: string | null;
  DefaultValue: string | null;
  Description: string | null;
  Display: string | IFormFieldDisplayType;
  FarmType?: string | null;
  FarmGroup?: string | null;
  FieldType: string | null;
  Level: string | null;
  List: string | null;
  ListOptions: IListOption[] | null;
  MaxTol: number;
  MinTol: number;
  Position: number;
  Prefix: string | null;
  Required?: string | object;
  Section: string | null;
  Std: string | number | null;
  Suffix: string | null;
  Validation: string | null;
  Readonly: boolean | undefined;
  RepeaterID?: string;
}

export interface IRepeaterField {
  RepeaterID: string;
  Label: string;
  MaxRepeats: number;
  MaxRepeatsQuery: string;
  MinRepeats: number;
  MinRepeatsQuery: string;
  Fields: IFormField[];
  Type?: "bin";
}

export interface IFormValid {
  Ref: string;
  Valid: boolean;
  Complete: boolean;
  Required: boolean;
}

export interface IPenFormValid extends IFormValid {
  QuestionGroup?: string | null;
  Pen: string;
  // RepeaterID?: string;
}

export interface IFileUploadValue {
  saved: any[];
  pending: any[] | boolean;
  deleting: any[];
}

export interface IFormValue {
  Ref: string;
  Value: string | number | IFileUploadValue;
  QuestionGroup?: string | null;
  Score?: number | null;
  Text?: string | null;
  Days?: string | null;
  // RepeaterID?: string; // Important: not to be used for comparison on the frontend. This property cannot be guaranteed to exist yet. Only for backend comparison.
  Owner?: string | null;
}

export interface IBirdsAliveData {
  BirdsAlive: number;
  FemaleAlive: number;
  MaleAlive: number;
}

export interface IPenData {
  Pen: string;
  Values: IFormValue[];
  BirdsAlive?: IBirdsAliveData;
}

export interface ISQLDateObject {
  native: Date;
  normalised: Date;
  localised: Date;
  dateString: String;
  timeString: String;
}

export interface IFormData {
  ID: string | number | null | undefined;
  FarmCode: string;
  FormName: string;
  FormType: string;
  AuditStatus: number | null;
  DateApplies: string;
  _DateApplies: ISQLDateObject;
  House: number;
  LastModified: string;
  _LastModified: ISQLDateObject | null;
  ParentPWAID: string | null | undefined;
  PenValues: IPenData[];
  Status: number;
  _SendStatus?: FORM_DATA_QUEUE_STATUS;
}

export interface INetworkFormData {
  FarmCode: string;
  FormName: string;
  FormType: string;
  PWAID: string;
  AppVersion: string;
  Data: Omit<IFormData, "_DateApplies" | "_LastModified">;
}

export interface IFarmStandard {
  ID: string;
  BirdSex: string;
  BirdType: string;
  Days: number;
  FarmGroup: string;
  StdName: string;
  StdNo: number;
  Value: number | string;
}

export interface IBirdAge {
  Days: number;
  Weeks: number;
}

export interface ICustomLogicDataSource {
  [key: string]: any;
}

export interface ICustomLogicParams {
  Ref: string;
  RepeaterIndex?: number;
  ListOptions?: IListOption[];
  PenId: string;
  GroupId: string;
  BirdType: string;
  BirdSex: string;
  BirdAge: IBirdAge;
  HatchDate?: ISQLDateObject;
  FarmGroup: string;
  FormDate: Date | null;
}

export function getPenDataFromFormData(
  penId: string,
  data: IFormData | undefined
): IPenData | undefined {
  if (!data?.PenValues?.length) return;

  const penValues = data.PenValues.find(
    (fv) => fv.Pen.toString() === penId.toString()
  );

  return penValues;
}

export function getFormValueFromPenData({
  ref,
  questionGroup,
  // repeaterId,
  penData,
}: {
  ref: string;
  questionGroup?: string | null;
  // repeaterId?: string | null;
  penData?: IPenData | undefined;
}) {
  if (!penData?.Values?.length) return;

  const formValue = penData?.Values?.find(
    (fv) =>
      fv.Ref.toLowerCase() === ref.toLowerCase() &&
      (isNullEmptyOrWhitespace(questionGroup) ||
        fv.QuestionGroup?.toLowerCase() === questionGroup!.toLowerCase())
    // && (isNullEmptyOrWhitespace(repeaterId) || fv.RepeaterID?.toLowerCase() === repeaterId!.toLowerCase())
  );

  return formValue;
}

export function getCustomLogicDataSourceFormData(
  datasource: string | null,
  data: ICustomLogicDataSource
): any {
  datasource = datasource?.toLowerCase() ?? "this";

  const result = data[datasource] ?? null;

  //Uncomment for debugging
  //console.log("data", data, "datasource", datasource, "result", result);

  return result;
}

export interface ICustomListVariable {
  ref: string | null;
}

export interface ICustomLogicVariable {
  ref: string | null;
  property: string;
  datasource: string;
  group: string | null;
  pen: string | null;
  func: {
    name: string;
    args: unknown[];
  };
  isRepeater: boolean;
}

export interface ICustomLogicFuncParams {
  name: string;
  args: any[];
}

export type IUser = {
  username: string;
  fullname: string;
  email: string;
  permissionLevelId: number;
};

export type IUserMsal = {
  id: string; // active directory ID
  permissionGroupId: string;
} & IUser;

/**
 * @example
 * parseCustomLogicVariable("${V1.Text}")
 */
export function parseCustomListVariable(
  customLogicString: string
): ICustomListVariable {
  // const _originalCustomLogicString = customLogicString;
  const _result: ICustomListVariable = {
    ref: "",
  };

  (function convertLegacyCustomLogicString(): void {
    if (customLogicString.startsWith("list:")) {
      customLogicString = customLogicString.replace(/list:([^,]+)/i, "$1");
    }
  })();

  function getRef(): string | null {
    const _regex = /^([a-z0-9\-_]+)/i;
    const _match = customLogicString.match(_regex);
    let ref = _match ? _match[1].toLowerCase() : null;

    return ref;
  }
  _result.ref = getRef();

  return _result;
}

/**
 * @example
 * parseCustomLogicVariable("${V1.Text}")
 */
export function parseCustomLogicVariable(
  customLogicString: string,
  params: ICustomLogicParams
): ICustomLogicVariable {
  // const _originalCustomLogicString = customLogicString;
  const _result: ICustomLogicVariable = {
    ref: "",
    property: "",
    datasource: "",
    group: null,
    pen: null,
    func: {
      name: "",
      args: [],
    },
    isRepeater: false,
  };

  (function convertLegacyCustomLogicString(): void {
    customLogicString = customLogicString.replace(
      /^house:birdsalive$/i,
      "_birdsalive -p all"
    );

    customLogicString = customLogicString.replace(
      /^house:birdsalive:male$/i,
      "_birdsalive.male -p all"
    );

    customLogicString = customLogicString.replace(
      /^house:birdsalive:female$/i,
      "_birdsalive.female -p all"
    );

    customLogicString = customLogicString.replace(
      /^pen:birdsalive$/i,
      "_birdsalive"
    );

    customLogicString = customLogicString.replace(
      /^pen:birdsalive:male$/i,
      "_birdsalive.male"
    );

    customLogicString = customLogicString.replace(
      /^pen:birdsalive:female$/i,
      "_birdsalive.female"
    );

    if (customLogicString.startsWith("datediff:")) {
      customLogicString = customLogicString.replace(
        /datediff:([^,]+),([^,]+)/i,
        "$1 -f datediff($$$2)"
      );
    }

    if (customLogicString.startsWith("date:")) {
      customLogicString = customLogicString.replace(/date:this/i, "_formdate)");

      customLogicString = customLogicString.replace(
        /date:today/i,
        "_datetoday"
      );
    }

    if (customLogicString.startsWith("yesterday:")) {
      customLogicString = customLogicString.replace(
        /yesterday:([^,]+)/i,
        "$1 -d previous"
      );
    }

    if (customLogicString.startsWith("cv:")) {
      customLogicString = customLogicString.replace("cv:", "");
      const _variables = customLogicString.split(",");
      if (_variables === null) return;

      const firstVar = _variables?.shift();
      if (firstVar === undefined) return;

      customLogicString = `${firstVar} -f cv(${_variables
        .map((_var: string) => `$${_var}`)
        .join(",")})`;
    }

    if (customLogicString.startsWith("avg:")) {
      customLogicString = customLogicString.replace("avg:", "");
      const _variables = customLogicString.split(",");
      if (_variables === null) return;

      const firstVar = _variables?.shift();
      if (firstVar === undefined) return;

      customLogicString = `${firstVar} -f avg(${_variables
        .map((_var: string) => `$${_var}`)
        .join(",")})`;
    }

    if (customLogicString.startsWith("sd:")) {
      customLogicString = customLogicString.replace("sd:", "");
      const _variables = customLogicString.split(",");
      if (_variables === null) return;

      const firstVar = _variables?.shift();
      if (firstVar === undefined) return;

      customLogicString = `${firstVar} -f sd(${_variables
        .map((_var: string) => `$${_var}`)
        .join(",")})`;
    }
  })();

  function getOptions(): string[] | null {
    const _regex = /((?:-[a-z])(?:\s)*(?:[a-z0-9_]+(?:\([^()]+\))?)?)/gi;

    return customLogicString.match(_regex) ?? null;
  }
  const _options = getOptions();

  function getFlagValue(flag: string): { flag: string; value: string } | null {
    if (!_options?.length) return null;

    // const _regex = new RegExp(`(?:-${flag})(?:\\s)+([\\S]+)`, "i");
    // const _regex = new RegExp(`(?:-${flag})(?:\\s)+([a-z0-9]+(?:\\([^\\(\\)]+\\))?)`, "i");
    // const _regex = new RegExp(
    //   `(?<![(](?:[^)]+))-(${flag})(?:\\s)*([a-z0-9_]+(?:\\([^\\(\\)]+\\))?)?`,
    //   "i"
    // );

    // const _regex = new RegExp(`^[^(]+-(${flag})(?:\\s)*([a-z0-9_]+(?:\\([^\\(\\)]+\\))?)?`, 'i')
    const _regex = new RegExp(
      `^-(${flag})(?:\\s)*([a-z0-9_]+(?:\\([^\\(\\)]+\\))?)?`,
      "i"
    );

    for (const _option of _options) {
      const _match = _option.match(_regex);
      // console.log(customLogicString, _option, _match);

      if (_match) {
        const flag = _match[1]?.toLowerCase() ?? "";
        const value = _match[2]?.toLowerCase() ?? "";
        return { flag, value } ?? null;
      }
    }

    return null;
  }

  function getDataSource(): string {
    const dataSource = getFlagValue("d");

    return dataSource?.value?.toLowerCase() ?? DEFAULT_DATASOURCE;
  }
  _result.datasource = getDataSource();

  function getPen(): string | null {
    const pen = getFlagValue("p");

    if (pen?.value === "all") {
      // All pens
      return null;
    }

    // Default to the current 'PenId'
    if (pen === null || isNullEmptyOrWhitespace(pen.value)) {
      if (isNullEmptyOrWhitespace(params?.PenId)) {
        console.error(
          "No current 'PenId' provided and a pen flag was not found e.g. '-p 1'"
        );

        return null;
      }

      return params!.PenId;
    }

    return pen.value.toLowerCase();
  }
  _result.pen = getPen();

  function getFunc(): ICustomLogicFuncParams {
    const func = getFlagValue("f");
    const funcString = func?.value?.toLowerCase() ?? "";
    if (isNullEmptyOrWhitespace(funcString)) {
      return {
        name: "",
        args: [],
      };
    }

    const funcNameMatch = funcString.match(/^([a-z0-9]+)/i);
    const funcName = funcNameMatch?.[1] ?? null;

    const paramString = funcString.match(/\(([^)]+)\)/i);
    const params = paramString?.[1]?.split(",");

    return {
      name: funcName ?? "",
      args: params ?? [],
    };
  }
  _result.func = getFunc();

  function getGroup(): string | null {
    const group = getFlagValue("g");
    if (group === null) {
      return params?.GroupId ?? null;
    }

    return group?.value?.toLowerCase() ?? null;
  }
  _result.group = getGroup();

  function getIsRepeater() {
    const isRepeater = getFlagValue("r");

    return isRepeater ? true : false;
  }
  _result.isRepeater = getIsRepeater();

  function getRef(): string | null {
    const _regex = /^([a-z0-9\-_]+)/i;
    const _match = customLogicString.match(_regex);
    let ref = _match ? _match[1].toLowerCase() : null;

    if (ref === "_groupparent") {
      if (params?.GroupId === undefined && _result.group === null) {
        console.error(
          `Attempted to access '_groupparent' in ${customLogicString} but no 'GroupId' property provided in parseCustomLogicVariable params.`
        );

        return null;
      }

      if (_result.group !== null) {
        // group supplied in custom logic string, should use that
        return _result.group;
      }

      ref = params!.GroupId;
    } else if (ref === "_this") {
      if (params?.Ref === undefined) {
        console.error(
          `Attempted to access '_this' in ${customLogicString} but no 'Ref' property provided in parseCustomLogicVariable params.`
        );

        return null;
      }

      ref = params?.Ref;
    }

    return ref;
  }
  _result.ref = getRef();

  function getProperty(): string {
    const _regex = /^[a-z0-9:\-_]+\.([a-z0-9]+)/i;
    const _match = customLogicString.match(_regex);

    const property = _match ? _match[1] : DEFAULT_PROPERTY;
    const normalisedPropetrty =
      property.toLowerCase().charAt(0).toUpperCase() + property.slice(1);

    return normalisedPropetrty;
  }
  _result.property = getProperty();

  return _result;
}

export function parseCustomLogic(
  customLogicString: string,
  data: ICustomLogicDataSource,
  params: ICustomLogicParams
): string | undefined {
  if (
    customLogicString === undefined ||
    customLogicString === null ||
    customLogicString === "" ||
    typeof customLogicString != "string"
  )
    return customLogicString;

  const _regex = /\${([^{}]+)}/gi;
  const _result = new Map<string, string | undefined>();

  const customLogicStringExclCustomVariables = customLogicString
    .replace(/\${([^{}]+)}/g, "")
    .trim();
  const isMath = containsMathOperators(customLogicStringExclCustomVariables);

  function getPrevCharacter(index: number): string {
    let i = index;
    do {
      i -= 1;
      const char = customLogicString.charAt(i);
      if (/\S/.test(char)) {
        return char;
      }
    } while (i > 0);

    return "";
  }

  function getNextCharacter(index: number): string {
    let i = index;
    do {
      i += 1;
      const char = customLogicString.charAt(i);
      if (/\S/.test(char)) {
        return char;
      }
    } while (i <= customLogicString.length);

    return "";
  }

  function containsMathOperators(value: string): boolean {
    return /[+\-*/^%]/.test(value);
  }

  function normaliseValue(
    value: string | number,
    prevCharIndex: number,
    nextCharIndex: number
  ): string | undefined {
    let result = value ?? "";
    if (isNullEmptyOrWhitespace(value) && isMath) {
      const prevChar = getPrevCharacter(prevCharIndex);
      const nextChar = getNextCharacter(nextCharIndex);

      if (["/", "*"].includes(prevChar) || ["*"].includes(nextChar)) {
        result = "1";
      } else {
        result = "0";
      }
    }

    if (isNullEmptyOrWhitespace(result)) {
      return undefined;
    }

    return result.toString();
  }

  // console.group("parseCustomLogic", customLogicString);

  let _matches;
  while ((_matches = _regex.exec(customLogicString)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (_matches.index === _regex.lastIndex) {
      _regex.lastIndex++;
    }

    const prevCharIndex = _matches.index;
    const nextCharIndex = _regex.lastIndex - 1;

    for (const [i, m] of _matches.entries()) {
      if (i === 0) continue;

      const { ref, property, datasource, group, pen, func, isRepeater } =
        parseCustomLogicVariable(m, params);

      if (!ref) continue;

      const refWithRepeater = isRepeater
        ? `${ref}_${params.RepeaterIndex}`
        : ref;

      const key = getKey(
        _matches.index,
        refWithRepeater,
        group,
        pen,
        datasource
      );

      //Uncomment for debugging
      // prettier-ignore
      // console.log("m", m, "ref", ref, "property", property, "datasource", datasource, "group", group, "pen", pen, "func", func, "isRepeater", isRepeater, "refWithRepeater", refWithRepeater);
      // console.log("key", key)

      const _dataSource = getCustomLogicDataSourceFormData(datasource, data);
      if (!_dataSource) {
        throw new Error(
          `An attempt to access datasource '${datasource}' for custom logic "${customLogicString}" failed. Either remove the calculation or add the datasource.`
        );
      }

      if (datasource === "_standards") {
        // Standards
        const _standards = _dataSource as IFarmStandard[];

        const formValue = getFarmStandardsValue(_standards, ref);

        const normalisedValue = normaliseValue(
          formValue,
          prevCharIndex,
          nextCharIndex
        );

        _result.set(key, normalisedValue);

        continue;
      }

      if (
        [
          "_user",
          "_farm",
          "farm", // include to support legacy farm
          "_schedule",
          "_custom",
        ].includes(datasource)
      ) {
        // Farms

        const formValue = getDataSourceValue(_dataSource, ref, property);

        const normalisedValue = normaliseValue(
          formValue,
          prevCharIndex,
          nextCharIndex
        );

        _result.set(key, normalisedValue);

        continue;
      }

      //Form data

      const _formData = _dataSource as IFormData;
      // if (isNullEmptyOrWhitespace(_formData)) return "";

      if (ref === "_formdate") {
        let formValue = getFormDateValue(_formData, property);

        if (!isNullEmptyOrWhitespace(formValue)) {
          formValue = applyFunction(func, formValue)?.toString();
        }

        const normalisedValue = normaliseValue(
          formValue,
          prevCharIndex,
          nextCharIndex
        );

        _result.set(key, normalisedValue);

        continue;
      }

      if (ref === "_datetoday") {
        let formValue = getDateTodayValue();

        if (!isNullEmptyOrWhitespace(func?.name)) {
          formValue = applyFunction(func, formValue)?.toString();
        }

        const normalisedValue = normaliseValue(
          formValue,
          prevCharIndex,
          nextCharIndex
        );

        _result.set(key, normalisedValue);

        continue;
      }

      if (datasource === "_field") {
        const field = _dataSource as IFormField;
        const property = getFieldProperty(field, ref);

        if (ref === "filteredlistoptions" || ref === "listoptions") {
          const listOptions = property as IListOption[];

          if (func?.name === "last") {
            const formValue = listOptions[listOptions.length - 1]?.Value;

            const normalisedValue = normaliseValue(
              formValue,
              prevCharIndex,
              nextCharIndex
            );

            _result.set(key, normalisedValue);

            continue;
          }

          if (func?.name === "first") {
            const formValue = listOptions[0]?.Value;

            const normalisedValue = normaliseValue(
              formValue,
              prevCharIndex,
              nextCharIndex
            );

            _result.set(key, normalisedValue);

            continue;
          }
        }
      }

      if (_formData?.PenValues !== undefined) {
        for (const penValue of _formData.PenValues) {
          if (!isNullEmptyOrWhitespace(pen) && pen !== penValue.Pen.toString())
            continue; // Skip pen

          let formValue;

          if (ref === "_birdsalive") {
            formValue = getPenBirdsAliveValue(penValue, property);
          } else if (ref === "_groupparent") {
            formValue = getGroupParentValue(
              penValue,
              refWithRepeater,
              group,
              property
            )?.toString();
          } else {
            formValue = getFormValue(
              penValue,
              refWithRepeater,
              group,
              property
            )?.toString();
          }

          if (!isNullEmptyOrWhitespace(func?.name)) {
            formValue = applyFunction(func, formValue);
          }

          const normalisedValue = normaliseValue(
            formValue,
            prevCharIndex,
            nextCharIndex
          );
          let result = normalisedValue;
          // console.log("formValue", formValue, "result", result, "key", key);
          if (!isNullEmptyOrWhitespace(result)) {
            // Append previous values with the same key
            const prevValue = _result.get(key);
            result =
              prevValue && isNumeric(prevValue)
                ? (
                    parseInt(prevValue) + parseInt(normalisedValue as string)
                  ).toString()
                : normalisedValue;
          }

          _result.set(key, result);
        }
      }
    }
  }

  // console.groupEnd();

  //Replace all variables with their values
  const _resultArray = Array.from(_result.values()) ?? [];
  const result = customLogicString.replace(
    _regex,
    () => _resultArray.shift() as string
  );

  return result;

  function getFormDateValue(formData: IFormData, property: string): string {
    if (params.FormDate) {
      return localDateToSQL(params.FormDate, { includeOffset: false }) ?? "";
    }
    const formDate = formData._DateApplies?.normalised;

    return localDateToSQL(formDate, { includeOffset: false }) ?? "";
  }

  function getDateTodayValue(): string {
    const dateToday = localDate();

    return localDateToSQLDate(dateToday) ?? "";
  }

  function getFormValue(
    penValue: IPenData,
    ref: string,
    group: string | null,
    property: string
  ): string | number | IFileUploadValue {
    return (
      penValue.Values.find(
        (fv) =>
          fv.Ref.toLowerCase() === ref.toLowerCase() &&
          (isNullEmptyOrWhitespace(group) ||
            fv.QuestionGroup?.toLowerCase() === group?.toLowerCase())
      )?.[(property ?? DEFAULT_PROPERTY) as keyof IFormValue] ?? ""
    );
  }

  function getGroupParentValue(
    penValue: IPenData,
    ref: string,
    group: string | null,
    property: string
  ): string | number | IFileUploadValue {
    return (
      penValue.Values.find(
        (fv) => fv.Ref.toLowerCase() === group?.toLowerCase()
      )?.[(property ?? DEFAULT_PROPERTY) as keyof IFormValue] ?? ""
    );
  }

  function getKey(
    matchIndex: number,
    ref: string,
    group: string | null,
    pen: string | null,
    datasource: string
  ): string {
    return `${matchIndex}:${ref}${group ? `-g${group}` : ""}${
      pen ? `-p${pen}` : ""
    }${datasource ? `-d${datasource}` : ""}`;
  }

  function getFarmStandardsValue(
    _standards: IFarmStandard[],
    ref: string
  ): string {
    if (isNullEmptyOrWhitespace(params?.FarmGroup))
      throw new Error(
        "Farm group is required when accessing the standards datasource"
      );
    if (isNullEmptyOrWhitespace(params?.BirdType))
      throw new Error(
        "Bird type is required when accessing the standards datasource"
      );
    if (isNullEmptyOrWhitespace(params?.BirdSex))
      throw new Error(
        "Bird sex is required when accessing the standards datasource"
      );
    if (isNullEmptyOrWhitespace(params?.BirdAge?.Days))
      throw new Error(
        "BirdAge.Days is required when accessing the standards datasource"
      );

    const standard = _standards.find(
      (s) =>
        s.ID === ref &&
        s.FarmGroup === params.FarmGroup &&
        s.BirdType === params.BirdType &&
        s.BirdSex === params.BirdSex &&
        s.Days === params.BirdAge.Days
    );

    return standard?.Value?.toString() ?? "0";
  }

  function getFieldProperty(field: IFormField, ref: string): any {
    const property = Object.entries(field).find(
      ([key]) => key.toLowerCase() === ref.toLowerCase()
    );

    return property?.[1];
  }

  function getDataSourceValue(
    data: { [key: string]: any },
    ref: string,
    property: string
  ): any {
    const key = Object.keys(data).find((k) => k.toLowerCase() === ref);
    if (key === undefined) return "";

    const result = data?.[key];

    if (property !== "Value") {
      return result?.[property];
    }

    return result;
  }

  function getPenBirdsAliveValue(penData: IPenData, property: string): number {
    if (isNullEmptyOrWhitespace(penData)) return 0;

    property = property?.toLowerCase();

    if (property === "male") {
      const birdsalive = penData?.BirdsAlive?.MaleAlive ?? 0;

      // Total dead
      const totaldead = parseInt(
        penData?.Values?.find(
          (fv) => fv.Ref.toLowerCase() === "totaldeadmale"
        )?.Value?.toString() ?? "0"
      );

      // Birds removed
      const malesRemoved = parseInt(
        penData?.Values?.find(
          (v) => v.Ref.toLowerCase() === "totalmaleremoved"
        )?.Value?.toString() ?? "0"
      );

      return birdsalive - totaldead - malesRemoved;
    }

    if (property === "female") {
      const birdsalive = penData?.BirdsAlive?.FemaleAlive ?? 0;

      // Total dead
      const totaldead = parseInt(
        penData?.Values?.find(
          (fv) => fv.Ref.toLowerCase() === "totaldeadfemale"
        )?.Value?.toString() ?? "0"
      );

      // Birds removed
      const femalesRemoved = parseInt(
        penData?.Values?.find(
          (v) => v.Ref.toLowerCase() === "totalfemaleremoved"
        )?.Value?.toString() ?? "0"
      );

      return birdsalive - totaldead - femalesRemoved;
    }

    const birdsalive = penData?.BirdsAlive?.BirdsAlive ?? 0;

    // Total dead
    const totaldead = parseInt(
      penData?.Values?.find(
        (fv) => fv.Ref.toLowerCase() === "totaldead"
      )?.Value?.toString() ?? "0"
    );

    // Birds removed
    const malesRemoved = parseInt(
      penData?.Values?.find(
        (v) => v.Ref.toLowerCase() === "totalmaleremoved"
      )?.Value?.toString() ?? "0"
    );

    const femalesRemoved = parseInt(
      penData?.Values?.find(
        (v) => v.Ref.toLowerCase() === "totalfemaleremoved"
      )?.Value?.toString() ?? "0"
    );

    return birdsalive - totaldead - malesRemoved - femalesRemoved;
  }

  function applyFunction(
    func: ICustomLogicFuncParams,
    value: any
  ): string | number {
    if (isNullEmptyOrWhitespace(func.name)) return value;
    // console.log(`Applying function ${func.name}`);

    const args = [];

    if (func.args.length > 0) {
      for (const arg of func.args) {
        let newArg = arg?.toString()?.trim();
        if (newArg.startsWith("$")) {
          const constructSyntax = "${" + newArg.substring(1) + "}";
          const paramLogic = parseCustomLogic(constructSyntax, data, params);

          newArg = paramLogic;
        }

        args.push(newArg);
      }
    }

    if (func.name === "join") {
      // eslint-disable-next-line no-useless-escape
      const separator = args[0]?.replace("\\s", " ") ?? "";
      const values = [value, ...args.slice(1)].filter(
        (v) => !isNullEmptyOrWhitespace(v) && v !== "undefined" && v !== "null"
      );

      return values.join(separator);
    }

    if (func.name === "int") {
      // return parseInt(value);
      return roundToNearest(value, 0);
    }

    if (func.name === "dateadd") {
      const date = sqlDateObjectFromServerTZ(value);
      const offset = parseInt((args[0] ?? 0).toString());
      const unit = args[1] ?? "days";

      return (
        localDateToSQL(dateAdd(date.normalised, offset, unit), {
          includeOffset: false,
        }) ?? ""
      );
    }

    if (func.name === "calcbirdsageweeks") {
      if (isNullEmptyOrWhitespace(params?.HatchDate)) {
        console.error(
          "No 'HatchDate' property provided in parseCustomLogic params."
        );
        return 0;
      }

      const date = sqlDateObjectFromServerTZ(value);
      const birdAge = calculateBirdAge(
        params.HatchDate?.normalised,
        0,
        date.normalised
      );

      return birdAge.weeks;
    }

    if (func.name === "calcbirdsagedays") {
      if (isNullEmptyOrWhitespace(params?.HatchDate)) {
        console.error(
          "No 'HatchDate' property provided in parseCustomLogic params."
        );
        return 0;
      }

      const date = sqlDateObjectFromServerTZ(value);
      const birdAge = calculateBirdAge(
        params.HatchDate?.normalised,
        0,
        date.normalised
      );

      return birdAge.days;
    }

    if (func.name === "datesubtract") {
      const date = sqlDateObjectFromServerTZ(value);
      const offset = parseInt((args[0] ?? 0).toString());
      const unit = args[1] ?? "days";

      return (
        localDateToSQL(dateSubtract(date.normalised, offset, unit), {
          includeOffset: false,
        }) ?? ""
      );
    }

    if (func.name === "datediff") {
      const date1String = value?.toString();
      const date2String = args[0]?.toString();
      let date1: Date | undefined;
      let date2: Date | undefined;

      // Handle time fields
      if (isTimeString(date1String)) {
        date1 = getDateFromTimeString(date1String);
      } else {
        date1 = localDateFromSQL(date1String);
      }
      if (isTimeString(date2String)) {
        date2 = getDateFromTimeString(date2String);
      } else {
        date2 = localDateFromSQL(date2String);
      }

      if (date1 === undefined || date2 === undefined) {
        return 0;
      }

      return dateDiffInMilliseconds(date1, date2);

      function extractHoursMinutesFromTimeString(dateString: string): {
        hours: number;
        minutes: number;
      } {
        if (isNullEmptyOrWhitespace(dateString))
          return { hours: 0, minutes: 0 };

        const hours = dateString?.slice(0, 2) ?? "0";
        const minutes = dateString?.slice(3, 5) ?? "0";

        return { hours: parseInt(hours), minutes: parseInt(minutes) };
      }

      function isTimeString(dateString: string) {
        return dateString.length === 5;
      }

      function getDateFromTimeString(dateString: string) {
        if (isNullEmptyOrWhitespace(dateString)) return;

        // Is time only, e.g. 04:00
        // Convert to date using current day
        const extractedTime = extractHoursMinutesFromTimeString(dateString);
        const startOfCurrentDateTime = startOfFromDate(localDate(), "day");
        let date: Date;

        date = dateAdd(startOfCurrentDateTime, extractedTime.hours, "hours");
        date = dateAdd(date, extractedTime.minutes, "minutes");

        return date;
      }
    }

    if (func.name === "cv") {
      const cov = calcCoefficiencyVariance([
        value,
        ...args.filter((v) => !isNullEmptyOrWhitespace(v)),
      ]);

      return cov;
    }

    if (func.name === "avg") {
      const avg = calcAverage([
        value,
        ...args.filter((v) => !isNullEmptyOrWhitespace(v)),
      ]);

      return avg;
    }

    if (func.name === "sd") {
      const sd = calcStandardDeviation([
        value,
        ...args.filter((v) => !isNullEmptyOrWhitespace(v)),
      ]);

      return sd;
    }

    if (func.name === "last") {
      const last = args[args.length - 1];

      return last;
    }

    return value;
  }
}

export function hasPendingFileSubmission(
  value: IFormValue["Value"]
): value is IFileUploadValue {
  return value instanceof Object && (value?.pending as any[])?.length > 0;
}

export function hasSavedFiles(
  value: IFormValue["Value"]
): value is IFileUploadValue {
  return value instanceof Object && (value?.saved as any[])?.length > 0;
}

export function hasDeletingFiles(
  value: IFormValue["Value"]
): value is IFileUploadValue {
  return value instanceof Object && value?.deleting?.length > 0;
}

export function createFormFieldLookupKey(
  ref: string,
  group: string | null | undefined
) {
  const _refKey = ref.toLowerCase();
  const _questionGroupKey = group?.toLowerCase();
  const questionGroupKeySeparator = "|";

  return `${_refKey}${
    !isNullEmptyOrWhitespace(_questionGroupKey)
      ? `${questionGroupKeySeparator}${_questionGroupKey}`
      : ""
  }`;
}

export function getFormDataStatus(formValid: IFormValid[]) {
  if (!formValid?.length) return DATA_STATUS.INCOMPLETE;

  let status: number = DATA_STATUS.COMPLETE;

  for (let index = 0; index < formValid.length; index++) {
    const fv = formValid[index];
    if (!fv.Valid) {
      // When any data is invalid
      status = DATA_STATUS.ERROR;
      break; // Stop looping
    } else if (fv.Valid && !fv.Complete) {
      // When required field data is valid but is NOT complete.
      status = DATA_STATUS.DRAFT;
      break; // Stop looping
    }
  }

  return status;
}

export function isFieldInCalc(
  field: { ref: string; group?: string; dataSource: string },
  calc: string,
  params: ICustomLogicParams
) {
  const pattern = /\$\{([^}]+)\}/gi;

  let matches;
  while ((matches = pattern.exec(calc)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (matches.index === pattern.lastIndex) {
      pattern.lastIndex++;
    }

    for (const [i, match] of matches.entries()) {
      if (i === 0) continue;

      const { ref, datasource, group } = parseCustomLogicVariable(
        match,
        params
      );

      if (!ref) continue;

      //Uncomment for debugging
      // prettier-ignore
      // field.ref === "AW36" && console.log(
      //   "ref", ref,
      //   "datasource", datasource,
      //   "group", group
      // );
      // prettier-ignore
      // field.ref === "AW36" && console.log(
      //   JSON.stringify(field), ref === field.ref?.toLowerCase(),
      //   (isNullEmptyOrWhitespace(group) || group?.toLowerCase() === field.group?.toLowerCase()),
      //   datasource?.toLowerCase() === field.dataSource?.toLowerCase()
      // );

      if (
        ref.toLowerCase() === field.ref?.toLowerCase() &&
        (isNullEmptyOrWhitespace(group) || group?.toLowerCase() === field.group?.toLowerCase()) && // If `group` is null or empty, then it is a global field
        datasource?.toLowerCase() === field.dataSource?.toLowerCase()
      ) {
        return true;
      }
    }
  }

  // console.log("result", fieldRef, result);
  return false;
}

/**
 * @deprecated  Not currently being used
 */
export function createFilterTextFromFormData(
  form: IForm,
  formData: IFormData | undefined,
  defaultText: string
) {
  if (!formData?.PenValues) return defaultText;
  let result: string[] = [];

  const fieldLookup = form.FormFields.reduce((acc, field) => {
    acc[field.Ref?.toLowerCase()] = field;
    return acc;
  }, {} as { [key: string]: IFormField });

  for (const pen of formData.PenValues) {
    const penData = getPenDataFromFormData(pen.Pen, formData);
    if (isNullEmptyOrWhitespace(penData) || penData === undefined) continue;

    for (const value of penData.Values) {
      const field = fieldLookup[value.Ref?.toLowerCase()];

      if (!field) continue;

      const valueText = getReadonlyFormValueByFieldType(field, value);
      if (isNullEmptyOrWhitespace(valueText)) continue;

      result.push(`${field.Name}: ${valueText.trim()}`);
    }
  }

  if (!result.length) return defaultText;

  return `Filtered by ${result.join(", ")}`;
}

export function reduceFormDataToObject(formData: Partial<IFormData>) {
  const result: { [key: string]: IFormValue["Value"] } = {};

  if (formData === undefined || formData?.PenValues === undefined)
    return result;

  for (const penValue of formData.PenValues) {
    for (const formValue of penValue.Values) {
      result[formValue.Ref] = formValue.Value;
    }
  }

  return result;
}

// export function getRepeaterFieldValuesFromPenData(
//   repeaterId: string,
//   penData: IPenData | undefined
// ) {
//   return penData?.Values?.filter(
//     (fv) => fv.RepeaterID?.toLowerCase() === repeaterId.toLowerCase()
//   );
// }

export function getRepeaterFieldValuesFromPenData(
  fields: IFormField[],
  repeaterId: string,
  penData: IPenData | undefined
) {
  // example repeaterFieldValueRefs: ["repeaterName_0", "repeaterName_1", "repeaterName_2"]
  return penData?.Values?.filter(
    // using regex to match repeaterName_0, repeaterName_1, etc.
    (fv) =>
      fields.some((field) => new RegExp(`^${field.Ref}_\\d+$`).test(fv.Ref))
  );
}

// export function getFormFieldValueFromRepeaterData(
//   field: IFormField,
//   index: number,
//   repeaterData: IFormValue[] | undefined
// ) {
//   return getFormValueFromPenData({
//     ref: `${field.Ref}_${index}`,
//     questionGroup: field.QuestionGroup,
//     penData: {
//       Pen: "",
//       Values: repeaterData ?? [],
//     },
//   });
// }

export function extractIndexFromRepeaterRef(key: string) {
  // expected format: repeaterName_{index} or repeaterName_something_{index}
  const regex = /.*_(\d+)$/g;
  const matches = regex.exec(key);

  if (matches && matches.length > 1) {
    return parseInt(matches[1]);
  }
}

export function getHighestIndexFromRepeaterRefs(keys: string[]) {
  const indexes = keys.reduce((acc: number[], key) => {
    const index = extractIndexFromRepeaterRef(key);
    if (index) acc.push(index);

    return acc;
  }, []);

  return Math.max(...indexes);
}

export function hasFormValueChanged(
  newValue: IFormValue["Value"],
  prevValue: IFormValue["Value"] | undefined,
  field: IFormField
) {
  const _newValue = isNullEmptyOrWhitespace(newValue) ? "" : newValue;
  const _prevValue = isNullEmptyOrWhitespace(prevValue) ? "" : prevValue;

  // prettier-ignore
  // console.log("has changed:", field.Ref, "newValue:", _newValue, "prevValue:", _prevValue, field.Calculation, field.DefaultValue);

  if (
    !isEqual(_newValue, field.Calculation) && 
    !isEqual(_newValue, field.DefaultValue) && 
    !isEqual(_newValue, _prevValue) &&
    (hasSavedFiles(_newValue) ? !isEqual(_newValue?.saved?.join(","), _prevValue) : true)
  ) {
    // prettier-ignore
    // console.log("has changed:", field.Ref, "newValue:", _newValue, "prevValue:", _prevValue, field.Calculation, field.DefaultValue);
    return true;
  }

  return false;
}
